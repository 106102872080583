'use client';
import React, { ReactNode, useEffect, useState } from 'react';

type Props = {
  children: ReactNode;
};

const BjsTheme = ({ children }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadStylesheet = async () => {
      try {
        await import('@/app/styles/themes/bjs/_bjs-theme.scss');
        setIsLoaded(true);
      } catch (error) {
        console.error('Failed to load stylesheet:', error);
      }
    };

    loadStylesheet();
  }, []);

  if (!isLoaded) {
    return null;
  }

  return <>{children}</>;
};

export default BjsTheme;

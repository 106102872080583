import { Category, CategoryConfig } from '@/app/types/models';
import { BusinessCategory } from '../utils/constants';
import { getRetailerConfig } from './retailerService';

/**
 * Returns single category config based on categoryId
 * @param categoryId
 * @param categories
 */
export const getCategoryConfig = (categoryId: number, categories: CategoryConfig[]) =>
  categories.find((c) => categoryId === categoryId);

/**
 * Returns single category based on workOrderId
 * @param workOrderId
 * @param categories
 */
export const getCategoryByWorkOrderId = (
  workOrderId: number,
  categories: Category[],
): Category | undefined => {
  // find the category with the matching workOrderId
  return categories.find((category) => category.workOrderIds.includes(workOrderId));
};

/**
 * Returns single category config for a retailer based on category name
 * @param name
 * @param retailerId
 */
export const getCategoryConfigByName = (retailerId: number, name?: BusinessCategory) => {
  const retailerConfig = getRetailerConfig(retailerId);
  let categoryConfig = retailerConfig?.categories.find((c) => c.categoryName === name);

  if (!categoryConfig) {
    categoryConfig = retailerConfig?.categories.find((c) => c.categoryName === BusinessCategory.Default);
  }

  return categoryConfig;
};

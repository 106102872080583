export default function LowesLogo() {
  return (
    <svg
      width="70"
      height="32"
      viewBox="0 0 70 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Brand Logo" clipPath="url(#clip0_191_22815)">
        <g id="Vector">
          <path
            d="M7.25597 9.19234V12.3377H1.62891V30.5201H68.3696V12.3377H62.7458V9.19234H52.702L35.0183 1.61052L17.3375 9.19234H7.25597ZM17.0018 7.71018L35.002 -0.000976562L53.0042 7.71018H64.2267V10.8556H69.8505V32.0001H0.149139V10.8556H5.77285V7.71018H17.0018Z"
            fill="white"
          />
          <path
            d="M51.628 13.3339L51.6269 16.7513L53.8376 16.7556L54.2984 13.335L51.628 13.3339Z"
            fill="white"
          />
          <path
            d="M63.6352 16.7049C63.7798 16.7049 63.9072 16.6941 63.9072 16.5193C63.9072 16.3832 63.7809 16.3584 63.6645 16.3584H63.4324V16.7049H63.6352ZM63.4324 17.332H63.2651V16.2138H63.6905C63.9538 16.2138 64.0821 16.312 64.0821 16.5322C64.0821 16.7319 63.959 16.8204 63.7951 16.8398L64.1124 17.332H63.9236L63.6288 16.8473H63.4324V17.332ZM63.6374 17.5803C64.0767 17.5803 64.4222 17.2392 64.4222 16.7718C64.4222 16.312 64.0767 15.9655 63.6374 15.9655C63.1937 15.9655 62.8485 16.312 62.8485 16.7718C62.8485 17.2392 63.1937 17.5803 63.6374 17.5803ZM62.6541 16.7718C62.6541 16.2138 63.1052 15.8047 63.6374 15.8047C64.1674 15.8047 64.6196 16.2138 64.6196 16.7718C64.6196 17.332 64.1674 17.7411 63.6374 17.7411C63.1052 17.7411 62.6541 17.332 62.6541 16.7718Z"
            fill="white"
          />
          <path
            d="M11.2702 24.9796V13.3339H8.08385V27.7547H16.1999V24.9796H11.2702Z"
            fill="white"
          />
          <path
            d="M43.3402 27.7547H50.9231V24.9764H46.543V23.1662H50.9231V20.4946H46.543V18.6748H50.9231V15.8845H43.3402V27.7547Z"
            fill="white"
          />
          <path
            d="M23.4805 18.6748H20.6782V24.9678H23.4805V18.6748ZM26.6723 26.2382C26.6723 27.0769 25.9943 27.7547 25.1568 27.7547H19.0019C18.1654 27.7547 17.4876 27.0769 17.4876 26.2382V17.4023C17.4876 16.5646 18.1654 15.8845 19.0019 15.8845H25.1568C25.9943 15.8845 26.6723 16.5646 26.6723 17.4023V26.2382Z"
            fill="white"
          />
          <path
            d="M38.3276 15.8845V24.9796H36.4031V15.8845H33.6019V24.9796H31.6784V15.8845H28.4855V26.2382C28.4855 27.0769 29.1657 27.7547 30.0023 27.7547H33.7748C34.4103 27.7547 35.002 27.3164 35.002 26.6807C35.002 27.3164 35.5956 27.7547 36.2304 27.7547H40.0027C40.8404 27.7547 41.5195 27.0769 41.5195 26.2382V15.8845H38.3276Z"
            fill="white"
          />
          <path
            d="M62.1112 23.2686L62.1123 23.2711L62.0919 23.2276C62.0798 23.204 62.068 23.1791 62.0551 23.1544L62.0345 23.1155C61.5294 22.1041 60.3991 21.2568 58.5795 20.5292L58.5146 20.5044C58.1552 20.3641 57.0574 19.9334 56.9364 19.2372C56.9031 19.0418 56.9817 18.7352 57.1987 18.5496C57.4299 18.3164 57.7794 18.1977 58.2448 18.1977C58.9862 18.1977 59.8305 18.501 60.2675 18.6802C61.1882 19.0623 61.9945 19.4033 62.0044 19.4056L62.0852 19.4401V16.8495L62.057 16.8323C62.0388 16.8204 60.2999 15.7237 58.5393 15.4711C58.3376 15.4539 58.1378 15.4453 57.9457 15.4453C56.3267 15.4453 55.1004 16.0346 54.3016 17.1939C53.6313 18.147 53.5568 19.6549 54.1278 20.7807C54.8609 22.0534 56.0362 22.5844 57.174 23.0994C57.6424 23.3097 58.127 23.531 58.5814 23.7998L58.587 23.803C59.1007 24.1106 59.3608 24.5824 59.2528 25.0024C59.1417 25.4351 58.6452 25.7277 58.016 25.7277C57.9296 25.7277 57.8432 25.7212 57.759 25.7124L57.7287 25.7081C56.5209 25.5386 54.0728 24.6277 54.0471 24.6192L53.9693 24.5889V27.432L54.0092 27.4438C54.0318 27.4526 56.4021 28.2373 58.5071 28.2373C59.6556 28.2373 60.5233 28.0041 61.0825 27.5495C61.0965 27.54 62.3821 26.5069 62.4392 24.7487C62.4545 24.2608 62.3443 23.7641 62.1112 23.2686Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_191_22815">
          <rect width="69.7018" height="32" fill="white" transform="translate(0.149139)" />
        </clipPath>
      </defs>
    </svg>
  );
}

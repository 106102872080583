export default function BjsLogo() {
  return (
    <svg width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M43.6673 34.0367L22.7294 33.9973L21.6678 33.9953L20.6061 33.9933L0.272461 33.9551L0.328557 3.84179L37.8033 3.91682L38.9624 3.91914L43.7275 3.92868L43.6673 34.0367Z"
        fill="#CC0033"
      />
      <path
        d="M22.7294 33.9973C24.7721 32.1639 26.422 28.9117 26.4294 25.4964L26.4618 6.79793L21.1514 6.78511L21.1774 24.3216C21.1745 27.1986 20.1246 29.483 18.0747 30.7189L20.6019 33.9884L21.6673 33.9914L22.7294 33.9973Z"
        fill="white"
      />
      <path
        d="M11.3986 30.5891L3.15321 30.5759L3.20027 6.74527L11.7198 6.75903C15.0391 6.76408 17.91 8.24327 17.8987 12.6156C17.8945 14.7706 16.9345 17.0278 14.6394 17.877C16.6601 18.4947 18.6049 20.3781 18.5979 23.2497C18.5902 27.5935 16.1558 30.6003 11.3986 30.5891ZM10.3755 11.4414L8.52369 11.4365L8.51895 15.9492L10.4336 15.9522C11.8715 15.9584 12.4178 14.9343 12.4222 13.7031C12.4219 12.4713 11.7755 11.4428 10.3755 11.4414ZM10.2897 20.5005L8.50949 20.4997L8.49844 25.7959L10.3455 25.8001C11.8515 25.8003 12.5062 24.6406 12.507 23.1684C12.5114 21.9757 12.0983 20.5047 10.2897 20.5005Z"
        fill="white"
      />
      <path
        d="M35.0517 31.0513C33.0607 31.0436 31.0109 30.427 28.9912 29.2224L30.6928 25.34C31.8353 26.0936 33.7342 27.1484 35.139 27.1504C35.9858 27.1503 36.5738 26.7183 36.5754 26.1652C36.5795 23.5867 29.7453 25.4143 29.7558 20.3154C29.7632 17.208 32.0166 14.9582 35.5379 14.9644C37.9861 14.9715 39.4176 15.6456 41.0144 16.4992L39.4603 20.1642C38.3165 19.574 37.3748 18.9466 36.2365 18.8912C35.3808 18.847 34.6127 19.1409 34.6111 19.848C34.6218 22.2311 41.5981 20.3057 41.5836 25.6688C41.5785 28.9496 38.9343 31.0545 35.0517 31.0513Z"
        fill="white"
      />
      <path
        d="M36.601 4.16561C33.2308 6.6899 30.9762 8.91072 30.9762 8.91072L28.4214 6.28728L29.9835 14.6329L29.9911 14.7253C29.9911 14.7253 32.4224 10.9724 36.0865 6.94028C37.0462 5.88117 38.0365 4.84973 38.9607 3.9209L36.935 3.91864L36.601 4.16561Z"
        fill="white"
      />
      <path
        d="M39.3493 11.292C40.0321 11.2953 40.5694 11.8483 40.5648 12.5406C40.5685 13.2435 40.0244 13.7868 39.3363 13.7875C38.6576 13.7895 38.1055 13.2395 38.1065 12.5371C38.1111 11.8448 38.6611 11.2927 39.3398 11.2908L39.3493 11.292ZM39.3439 11.4886C38.7955 11.4876 38.3426 11.9555 38.3469 12.5382C38.3406 13.1293 38.7878 13.594 39.3457 13.5962C39.8982 13.6026 40.3417 13.1335 40.3427 12.5466C40.339 11.9591 39.9018 11.4908 39.3487 11.4892L39.3439 11.4886ZM39.1034 13.2629L38.8872 13.26L38.8869 11.8742C39.0047 11.8553 39.1125 11.8398 39.2807 11.8416C39.4874 11.8433 39.6266 11.8847 39.7101 11.9482C39.7941 12.0068 39.8398 12.1038 39.8375 12.2382C39.8393 12.4165 39.7144 12.5308 39.5693 12.5753L39.5681 12.585C39.686 12.6046 39.773 12.7164 39.8003 12.9219C39.8312 13.1374 39.8643 13.2186 39.8875 13.2648L39.6518 13.2642C39.6192 13.2168 39.5866 13.0925 39.5611 12.9113C39.5255 12.7337 39.4378 12.665 39.2596 12.6668L39.1056 12.6668L39.1034 13.2629ZM39.1079 12.4936L39.2714 12.4948C39.4496 12.493 39.6065 12.4308 39.6089 12.2578C39.6053 12.1323 39.5146 12.0103 39.2689 12.0134L39.109 12.0223L39.1079 12.4936Z"
        fill="white"
      />
      <path
        d="M22.7294 33.9973L21.6678 33.9953L20.6061 33.9933L21.4651 35C22.0866 34.5567 22.3509 34.3418 22.7294 33.9973Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.9607 3.9209L37.8033 3.91682L38.9624 3.91914C38.9619 3.91974 38.9613 3.92031 38.9607 3.9209Z"
        fill="#CC0033"
      />
      <path
        d="M43.0151 1.34579e-09C43.0151 1.34579e-09 39.8656 1.75794 36.9359 3.91376L37.8033 3.91682L38.9624 3.91914C41.1967 1.66313 43.0151 1.34579e-09 43.0151 1.34579e-09Z"
        fill="white"
      />
    </svg>
  );
}

export default function FNDLogo() {
  return (
    <svg width="92" height="26" viewBox="0 0 92 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Brand Logo" clip-path="url(#clip0_5843_31322)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.568 21.6602L32.5696 21.6629V21.658L32.568 21.6602ZM0.15625 0.206375V12.2509H4.77898V7.95275H10.2968V5.05483H4.77898V3.10537H10.4699V0.206375H0.15625ZM21.8067 8.69592H16.1348V0.206375H11.5121V12.2509H21.8067V8.69592ZM28.8784 2.89792C30.6377 2.89792 30.7582 5.10629 30.7744 6.22862C30.7582 7.52104 30.7077 9.55879 28.8784 9.55879C27.2226 9.55879 27.0338 7.52104 27.0338 6.22862C27.0338 5.10629 27.1711 2.89792 28.8784 2.89792ZM28.8784 12.4562C33.0872 12.4562 35.6033 10.3345 35.6033 6.22862C35.6033 2.19104 33.0866 0 28.8784 0C24.6717 0 22.2055 2.19104 22.2055 6.22862C22.2055 10.3345 24.6717 12.4562 28.8784 12.4562ZM43.0417 2.89792C44.8021 2.89792 44.9215 5.10629 44.9383 6.22862C44.922 7.52104 44.871 9.55879 43.0422 9.55879C41.3865 9.55879 41.1977 7.52104 41.1977 6.22862C41.1977 5.10629 41.3355 2.89846 43.0422 2.89846M43.0422 12.4562C47.2499 12.4562 49.7683 10.3345 49.7683 6.22862C49.7688 2.19104 47.2499 0 43.0422 0C38.834 0 36.3693 2.19104 36.3693 6.22862C36.3693 10.3345 38.834 12.4562 43.0422 12.4562ZM55.2671 2.89792H56.5437C57.4762 2.89792 58.3551 3.05392 58.3551 4.1405C58.3551 5.36521 57.4231 5.46812 56.4748 5.46812H55.2671V2.89792ZM55.4738 8.15912H56.5095C57.078 8.15912 57.5435 8.23008 57.8197 8.78096C58.1994 9.50679 58.2504 11.3864 58.4582 12.2503H63.2686C62.7869 11.076 62.8373 9.21267 62.1315 7.9365C61.6997 7.29787 61.0975 6.81417 60.0971 6.64083V6.60833C61.8207 6.19396 62.9767 5.26175 62.9767 3.41521C62.9767 1.10392 61.2 0.205833 58.3193 0.205833H50.8538V12.2503H55.4744L55.4738 8.15912ZM4.93359 22.7646V16.5192H6.07178C7.72644 16.5192 8.142 17.589 8.142 19.6592C8.142 21.6602 7.76224 22.7646 6.0886 22.7646H4.93359ZM12.9687 19.779C12.9687 14.8975 10.5383 13.7247 7.09007 13.7247H0.312493V25.766H6.90019C10.3484 25.766 12.9687 24.3349 12.9687 19.779ZM24.6186 16.6232V13.7247H14.1872V25.766H24.7396V22.8675H18.7041V21.2295H24.3603V18.3305H18.7041V16.6232H24.6186Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M32.6439 25.9729C33.8179 25.9729 35.0928 25.733 35.9554 25.4908V22.2993C35.1102 22.5924 34.3008 22.7652 33.4544 22.7652C31.5421 22.7652 30.1625 21.7116 30.1625 19.6425C30.1625 17.6762 31.6435 16.6238 33.6112 16.6238C34.3859 16.6238 35.1612 16.8307 35.9218 17.0722V13.897C34.6794 13.6727 33.9205 13.5167 32.6439 13.5167C28.7813 13.5167 25.4367 15.5372 25.4367 19.5731C25.4367 23.8691 28.644 25.9729 32.6439 25.9729ZM43.1719 23.075C41.5151 23.075 41.3241 21.0394 41.3241 19.747C41.3241 18.6241 41.4635 16.4157 43.1713 16.4157C44.9291 16.4157 45.0506 18.6241 45.0674 19.747C45.0511 21.0394 44.9985 23.075 43.1719 23.075ZM43.1713 13.5167C38.9631 13.5167 36.4968 15.7078 36.4968 19.747C36.4968 23.8512 38.9631 25.9735 43.1713 25.9735C47.3774 25.9735 49.8957 23.8512 49.8957 19.747C49.8957 15.7078 47.3774 13.5167 43.1713 13.5167ZM55.3968 18.9876V16.4163H56.6717C57.6037 16.4163 58.4821 16.5717 58.4821 17.6583C58.4821 18.8825 57.5517 18.9876 56.6028 18.9876H55.3968ZM57.9477 22.2993C58.328 23.0235 58.3795 24.9047 58.5862 25.766H63.3967C62.9149 24.5949 62.9664 22.731 62.259 21.4532C61.8283 20.8157 61.2255 20.332 60.2241 20.1597V20.1256C61.9492 19.7107 63.1042 18.7796 63.1042 16.933C63.1042 14.6223 61.3281 13.7247 58.4484 13.7247H50.9813V25.766H55.6035V21.6775H56.6375C57.2055 21.6775 57.6721 21.7468 57.9477 22.2993Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.4739 26H66.1456V22.7912C66.8638 24.4297 68.0112 25.4605 69.4684 25.9973L69.4739 26ZM75.6976 26H69.4739L69.4749 26.0005H75.6954L75.6976 26ZM85.2425 26C83.5683 25.0911 81.3662 23.042 81.3662 23.042L81.3391 22.9515C79.9812 24.2076 78.5056 25.2108 76.967 25.675C76.6135 25.7829 76.2654 25.8653 75.9216 25.9467C75.8467 25.9644 75.7721 25.9821 75.6976 26H85.2425ZM90.4072 26H91.8405V25.2492L91.8394 23.1638L91.8285 0.00108333L80.1239 0.000541667C84.0641 1.45167 84.2551 4.48717 83.2026 6.71125C82.0248 9.204 77.5225 11.0673 77.5225 11.0673C77.5225 11.0673 78.5262 13.0325 80.8481 16.4038C81.5626 17.3951 82.2136 18.2417 82.8142 18.9713C83.1017 18.6528 86.4061 14.9202 86.4642 12.2303C86.5146 9.82204 83.8873 10.2554 83.8873 10.2554V9.35404H91.8323L91.8329 10.237C91.4867 10.3101 89.6818 10.7678 88.7444 12.5417L88.708 12.6105C88.0896 13.7805 86.3141 17.1399 83.8726 20.1955C86.6448 23.2309 88.1535 23.4378 89.4447 23.615C89.5001 23.6225 89.555 23.6301 89.6097 23.6378C90.8211 23.81 91.5171 23.4168 91.8394 23.1638L91.8405 25.2492C91.4455 25.5233 90.9019 25.7871 90.4072 26ZM75.9791 0.000541667C74.5067 0.615875 72.9226 1.80917 72.3627 3.58637C71.3602 6.75892 72.8716 10.1243 73.6338 11.3165C73.5241 11.3807 73.3884 11.4578 73.231 11.5473C71.619 12.4636 67.7311 14.6737 66.1456 17.5077V0L75.9791 0.000541667ZM69.7223 19.9528C68.9682 15.2122 74.2957 12.8537 74.2957 12.8537C77.8166 19.4729 80.1608 21.9592 80.1608 21.9592C76.3681 25.4085 70.5404 25.1279 69.7223 19.9528ZM75.2049 4.46333C75.5196 1.30921 78.2484 1.08767 78.8897 1.18625C79.5309 1.28646 82.1067 2.17046 81.5393 5.14637C80.9658 8.15317 76.8943 9.93308 76.8943 9.93308C76.8943 9.93308 75.0595 5.93179 75.2049 4.46333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5843_31322">
          <rect width="91.6842" height="26" fill="white" transform="translate(0.15625)" />
        </clipPath>
      </defs>
    </svg>
  );
}
